import React from "react";
import {useOverrides, Override} from "@quarkly/components";
import {Image, Box, Text, Section} from "@quarkly/widgets";

const defaultProps = {
    "padding": "48px 0 48px 0",
    "sm-padding": "60px 0 60px 0",
    "quarkly-title": "CDZVBenefits",
    "background": "--color-white"
};
const overrides = {
    "box": {
        "kind": "Box",
        "props": {
            "width": "50%",
            "lg-width": "100%",
            "lg-margin": "0px 0px 0px 0px",
            "margin": "0px 0px 0px 0px",
            "lg-padding": "0px 0px 0px 0px",
            "lg-justify-content": "center",
            "pointer-events": "none",
            "padding": "0px 0 0px 0",
            "flex": "0 1 auto",
            "order": "0",
            "display": "flex",
            "text-align": "center",
            "position": "relative"
        }
    },
    "image": {
        "kind": "Image",
        "props": {
            "src": "https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/btc_strategy.png?v=2024-06-03T15:59:19.333Z",
            "object-fit": "cover",
            "sm-min-height": "100vw",
            "disableOptimization": false,
            "pointer-events": "none",
            "transition": "transform 0.5s ease-in-out 0s",
            "transform": "translateY(10px)",
            "hover-transform": "translateY(0px)",
            "align-self": "center"
        }
    },
    "box1": {
        "kind": "Box",
        "props": {
            "display": "flex",
            "width": "50%",
            "flex-direction": "column",
            "align-items": "flex-start",
            "lg-width": "100%",
            "lg-align-items": "flex-start",
            "lg-margin": "0px 0px 0px 0px",
            "padding": "48px 10px 8px 64px",
            "justify-content": "center",
            "background": "#ffffff",
            "md-padding": "36px 40px 8px 40px",
            "sm-padding": "36px 24px 8px 24px",
            "order": "1"
        }
    },
    "text": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 16px 0px",
            "color": "--dark",
            "font": "--headline3",
            "lg-text-align": "center",
            "sm-text-align": "left",
            "children": "Економія часу та грошей"
        }
    },
    "text1": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 40px 0px",
            "color": "--greyD2",
            "font": "--base",
            "lg-text-align": "left",
            "sm-text-align": "left",
            "children": "Жодних навичок програмування не потрібно; запускайте стратегії миттєво!"
        }
    },
    "text2": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 16px 0px",
            "color": "--dark",
            "font": "--headline3",
            "lg-text-align": "center",
            "sm-text-align": "left",
            "children": "Тестування без ризику"
        }
    },
    "text3": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 40px 0px",
            "color": "--greyD2",
            "font": "--base",
            "lg-text-align": "left",
            "lg-margin": "0px 0px 36px 0px",
            "sm-text-align": "left",
            "children": "Оцінюйте стратегії на основі історичних даних для зниження ринкового ризику."
        }
    },
    "text4": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 16px 0px",
            "color": "--dark",
            "font": "--headline3",
            "lg-text-align": "center",
            "sm-text-align": "left",
            "children": "Необмежені можливості налаштування"
        }
    },
    "text5": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 40px 0px",
            "color": "--greyD2",
            "font": "--base",
            "lg-text-align": "left",
            "lg-margin": "0px 0px 36px 0px",
            "sm-text-align": "left",
            "children": "Комбінуйте і поєднуйте індикатори, щоб створювати стратегії, що відповідають вашому стилю торгівлі."
        }
    },
    "text6": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 16px 0px",
            "color": "--dark",
            "font": "--headline3",
            "lg-text-align": "center",
            "sm-text-align": "left",
            "children": "Сповіщення в режимі реального часу"
        }
    },
    "text7": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 40px 0px",
            "color": "--greyD2",
            "font": "--base",
            "lg-text-align": "left",
            "lg-margin": "0px 0px 36px 0px",
            "sm-text-align": "left",
            "children": "Будьте на крок попереду за допомогою автоматичних сповіщень Telegram або інтеграції з веб-хуками."
        }
    }
};

const CdzvBenefitsUA = props => {
    const {
        override,
        children,
        rest
    } = useOverrides(props, overrides, defaultProps);
    return <Section {...rest}>
        <Override
            slot="SectionContent"
            flex-direction="row"
            flex-wrap="wrap"
            sm-min-width="280px"
            background="rgba(255, 255, 255, 0)"
        />
        <Box {...override("box")}>
            <Image {...override("image")} />
        </Box>
        <Box {...override("box1")}>
            <Text {...override("text")} />
            <Text {...override("text1")} />
            <Text {...override("text2")} />
            <Text {...override("text3")} />
            <Text {...override("text4")} />
            <Text {...override("text5")} />
            <Text {...override("text6")} />
            <Text {...override("text7")} />
        </Box>
        {children}
    </Section>;
};

Object.assign(CdzvBenefitsUA, {
    ...Section,
    defaultProps,
    overrides
});
export default CdzvBenefitsUA;
import React from "react";
import {useOverrides} from "@quarkly/components";
import {Text, Box, Section} from "@quarkly/widgets";

const defaultProps = {
    "padding": "60px 0",
    "sm-padding": "40px 0",
    "color": "--dark",
    "background": "--color-white",
    "id": "faq"
};
const overrides = {
    "box": {
        "kind": "Box",
        "props": {
            "margin": "-16px -16px -16px -16px",
            "display": "flex",
            "flex-wrap": "wrap"
        }
    },
    "box1": {
        "kind": "Box",
        "props": {
            "padding": "16px 16px 16px 16px",
            "width": "33.333%",
            "md-width": "100%"
        }
    },
    "box2": {
        "kind": "Box",
        "props": {
            "display": "flex",
            "flex-direction": "column"
        }
    },
    "text": {
        "kind": "Text",
        "props": {
            "as": "h2",
            "font": "--headline1",
            "margin": "0 0 8px 0",
            "children": "FAQs"
        }
    },
    "text1": {
        "kind": "Text",
        "props": {
            "as": "p",
            "font": "--lead",
            "margin": "0",
            "color": "--greyD2",
            "children": "Нужны ответы? У нас они есть!"
        }
    },
    "box3": {
        "kind": "Box",
        "props": {
            "padding": "16px 16px 16px 16px",
            "width": "66.66%",
            "md-width": "100%"
        }
    },
    "box4": {
        "kind": "Box",
        "props": {
            "display": "flex",
            "flex-direction": "row",
            "flex-wrap": "wrap"
        }
    },
    "box5": {
        "kind": "Box",
        "props": {
            "display": "flex",
            "flex-direction": "column",
            "width": "50%",
            "padding": "16px 16px 16px 16px",
            "sm-width": "100%"
        }
    },
    "box6": {
        "kind": "Box",
        "props": {
            "display": "flex",
            "flex-direction": "column"
        }
    },
    "text2": {
        "kind": "Text",
        "props": {
            "as": "h3",
            "font": "--headline3",
            "margin": "12px 0",
            "children": "Нужны ли мне навыки программирования?"
        }
    },
    "text3": {
        "kind": "Text",
        "props": {
            "as": "p",
            "font": "--base",
            "margin": "12px 0",
            "color": "--greyD2",
            "children": "Нет, Code Zero Visual Trading разработан так, чтобы быть полностью доступным без программирования. Создавайте, тестируйте и автоматизируйте стратегии визуально!"
        }
    },
    "box7": {
        "kind": "Box",
        "props": {
            "display": "flex",
            "flex-direction": "column",
            "width": "50%",
            "padding": "16px 16px 16px 16px",
            "sm-width": "100%"
        }
    },
    "box8": {
        "kind": "Box",
        "props": {
            "display": "flex",
            "flex-direction": "column"
        }
    },
    "text4": {
        "kind": "Text",
        "props": {
            "as": "h3",
            "font": "--headline3",
            "margin": "12px 0",
            "children": "Какие типы торговых стратегий поддерживаются?"
        }
    },
    "text5": {
        "kind": "Text",
        "props": {
            "as": "p",
            "font": "--base",
            "margin": "12px 0",
            "color": "--greyD2",
            "children": "Code Zero поддерживает различные стратегии, включая стратегии следования за трендом, работа в диапазоне, сеточные стратегии."
        }
    },
    "box9": {
        "kind": "Box",
        "props": {
            "display": "flex",
            "flex-direction": "column",
            "width": "50%",
            "padding": "16px 16px 16px 16px",
            "sm-width": "100%"
        }
    },
    "box10": {
        "kind": "Box",
        "props": {
            "display": "flex",
            "flex-direction": "column"
        }
    },
    "text6": {
        "kind": "Text",
        "props": {
            "as": "h3",
            "font": "--headline3",
            "margin": "12px 0",
            "children": "Можно ли интегрировать пользовательские индикаторы?"
        }
    },
    "text7": {
        "kind": "Text",
        "props": {
            "as": "p",
            "font": "--base",
            "margin": "12px 0",
            "color": "--greyD2",
            "children": "Да! Code Zero позволяет использовать как стандартные индикаторы TradingView, так и пользовательские индикаторы, созданные сообществом TradingView."
        }
    },
    "box11": {
        "kind": "Box",
        "props": {
            "display": "flex",
            "flex-direction": "column",
            "width": "50%",
            "padding": "16px 16px 16px 16px",
            "sm-width": "100%"
        }
    },
    "box12": {
        "kind": "Box",
        "props": {
            "display": "flex",
            "flex-direction": "column"
        }
    },
    "text8": {
        "kind": "Text",
        "props": {
            "as": "h3",
            "font": "--headline3",
            "margin": "12px 0",
            "children": "Как настроить уведомления?"
        }
    },
    "text9": {
        "kind": "Text",
        "props": {
            "as": "p",
            "font": "--base",
            "margin": "12px 0",
            "color": "--greyD2",
            "children": "Вы можете настроить отправку уведомлений через Telegram или любую другую внешнюю систему с помощью отправки webhook-сообщений."
        }
    }
};

const CdzvFaqRU = props => {
    const {
        override,
        children,
        rest
    } = useOverrides(props, overrides, defaultProps);
    return <Section {...rest}>
        <Box {...override("box")}>
            <Box {...override("box1")}>
                <Box {...override("box2")}>
                    <Text {...override("text")} />
                    <Text {...override("text1")} />
                </Box>
            </Box>
            <Box {...override("box3")}>
                <Box {...override("box4")}>
                    <Box {...override("box5")}>
                        <Box {...override("box6")}>
                            <Text {...override("text2")} />
                            <Text {...override("text3")} />
                        </Box>
                    </Box>
                    <Box {...override("box7")}>
                        <Box {...override("box8")}>
                            <Text {...override("text4")} />
                        </Box>
                        <Text {...override("text5")} />
                    </Box>
                    <Box {...override("box9")}>
                        <Box {...override("box10")}>
                            <Text {...override("text6")} />
                            <Text {...override("text7")} />
                        </Box>
                    </Box>
                    <Box {...override("box11")}>
                        <Box {...override("box12")}>
                            <Text {...override("text8")} />
                            <Text {...override("text9")} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
        {children}
    </Section>;
};

Object.assign(CdzvFaqRU, {
    ...Section,
    defaultProps,
    overrides
});
export default CdzvFaqRU;
import React from "react";
import {useOverrides, Override} from "@quarkly/components";
import {Text, Section} from "@quarkly/widgets";
import QuarklycommunityKitYouTube from "./QuarklycommunityKitYouTube";
import {Image} from "antd";

const toolkitImg = "/assets/toolkit_ru.svg";

const defaultProps = {
    "padding": "0px 0 0px 0",
    "quarkly-title": "CdzvScam",
    "background": "--color-light",
    "display": "flex"
};
const overrides = {
    "text": {
        "kind": "Text",
        "props": {
            "margin": "50px 0px -30px 0px",
            "font": "normal 600 46px/1.2 --fontFamily-sans",
            "text-align": "center",
            "md-margin": "50px 0px -30px 0px",
            "background": "rgb(200,234,230)",
            "children": "CDZV Toolkit"
        }
    },
    "quarklycommunityKitYouTube": {
        "kind": "QuarklycommunityKitYouTube",
        "props": {
            "url": "https://www.youtube.com/watch?v=bEvSWtGzcZs",
            "modestbranding": true,
            "showOverlay": true
        }
    }
};

const CdzvToolkitRU = props => {
    const {
        override,
        children,
        rest
    } = useOverrides(props, overrides, defaultProps);
    return <Section {...rest}>
        <Override slot="SectionContent"/>
        <Text {...override("text")} />
        <Image src={toolkitImg} preview={false} />
        <QuarklycommunityKitYouTube {...override("quarklycommunityKitYouTube")} />
        {children}
    </Section>;
};

Object.assign(CdzvToolkitRU, {
    ...Section,
    defaultProps,
    overrides
});
export default CdzvToolkitRU;
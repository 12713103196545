import React from "react";
import {useOverrides, Override} from "@quarkly/components";
import {Text, Box, Section} from "@quarkly/widgets";

const defaultProps = {
    "padding": "50px 0 50px 0",
    "background": "--color-light"
};
const overrides = {
    "box": {
        "kind": "Box",
        "props": {
            "width": "25%",
            "padding": "15px 15px 15px 15px",
            "align-items": "center",
            "display": "flex",
            "justify-content": "center",
            "flex-direction": "column",
            "lg-width": "50%",
            "sm-width": "100%"
        }
    },
    "text": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 16px 0px",
            "font": "--headline1",
            "color": "--dark",
            "children": "90%"
        }
    },
    "text1": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 0px 0px",
            "font": "--base",
            "color": "--greyD2",
            "text-align": "center",
            "children": <>
                меньше времени на создание стратегии
                <br/>
                {" "}
            </>
        }
    },
    "box1": {
        "kind": "Box",
        "props": {
            "width": "25%",
            "padding": "15px 15px 15px 15px",
            "display": "flex",
            "align-items": "center",
            "justify-content": "center",
            "flex-direction": "column",
            "lg-width": "50%",
            "sm-width": "100%"
        }
    },
    "text2": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 16px 0px",
            "font": "--headline1",
            "color": "--dark",
            "children": "90%"
        }
    },
    "text3": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 0px 0px",
            "font": "--base",
            "color": "--greyD2",
            "text-align": "center",
            "children": <>
                экономия времени при бэктестировании
                <br/>
            </>
        }
    },
    "box2": {
        "kind": "Box",
        "props": {
            "width": "25%",
            "padding": "15px 15px 15px 15px",
            "display": "flex",
            "align-items": "center",
            "justify-content": "center",
            "flex-direction": "column",
            "lg-width": "50%",
            "sm-width": "100%"
        }
    },
    "text4": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 16px 0px",
            "font": "--headline1",
            "color": "--dark",
            "children": "80%"
        }
    },
    "text5": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 0px 0px",
            "font": "--base",
            "color": "--greyD2",
            "text-align": "center",
            "children": "экономьте деньги на создании стратегии"
        }
    },
    "box3": {
        "kind": "Box",
        "props": {
            "width": "25%",
            "padding": "15px 15px 15px 15px",
            "display": "flex",
            "align-items": "center",
            "justify-content": "center",
            "flex-direction": "column",
            "lg-width": "50%",
            "sm-width": "100%"
        }
    },
    "text6": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 16px 0px",
            "font": "--headline1",
            "color": "--dark",
            "children": "50%"
        }
    },
    "text7": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 0px 0px",
            "font": "--base",
            "color": "--greyD2",
            "text-align": "center",
            "children": "больше денег, заработанных в ходе торговли"
        }
    }
};

const CdzvStatsRU = props => {
    const {
        override,
        children,
        rest
    } = useOverrides(props, overrides, defaultProps);
    return <Section {...rest}>
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap"/>
        <Box {...override("box")}>
            <Text {...override("text")} />
            <Text {...override("text1")} />
        </Box>
        <Box {...override("box1")}>
            <Text {...override("text2")} />
            <Text {...override("text3")} />
        </Box>
        <Box {...override("box2")}>
            <Text {...override("text4")} />
            <Text {...override("text5")} />
        </Box>
        <Box {...override("box3")}>
            <Text {...override("text6")} />
            <Text {...override("text7")} />
        </Box>
        {children}
    </Section>;
};

Object.assign(CdzvStatsRU, {
    ...Section,
    defaultProps,
    overrides
});
export default CdzvStatsRU;
import React from "react";
import {useOverrides} from "@quarkly/components";
import {Text, Link, Box, Icon, LinkBox, Section} from "@quarkly/widgets";
import {FaDiscord, FaRedditSquare, FaMailBulk, FaYoutubeSquare} from "react-icons/fa";

const defaultProps = {
    "padding": "50px 0 40px 0",
    "quarkly-title": "Footer-12",
    "background": "--color-white"
};
const overrides = {
    "box": {
        "kind": "Box",
        "props": {
            "display": "grid",
            "lg-width": "100%",
            "flex-direction": "row",
            "lg-flex-direction": "row",
            "justify-content": "space-between",
            "width": "100%",
            "padding": "0 0px 0 0px",
            "lg-padding": "0 0px 30px 0px",
            "margin": "0px 0px 30px 0px",
            "md-flex-wrap": "wrap",
            "md-padding": "0 0px 0 0px",
            "md-margin": "0px 0px 30px 0px",
            "grid-template-columns": "repeat(3, 1fr)",
            "lg-align-items": "start",
            "grid-gap": "36px 34px",
            "md-grid-template-columns": "repeat(2, 1fr)",
            "sm-grid-template-columns": "1fr"
        }
    },
    "box1": {
        "kind": "Box",
        "props": {
            "align-items": "flex-start",
            "lg-align-items": "flex-start",
            "justify-content": "flex-start",
            "display": "grid",
            "lg-flex-direction": "column",
            "flex-direction": "column",
            "flex-wrap": "wrap",
            "align-content": "start",
            "grid-gap": "10px 0",
            "lg-justify-content": "start"
        }
    },
    "text": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 20px 0px",
            "font": "normal 500 25px/1.5 --fontFamily-sans",
            "md-margin": "0px 0px 10px 0px",
            "children": ""
        }
    },
    "link": {
        "kind": "Link",
        "props": {
            "border-color": "--color-primary",
            "display": "flex",
            "font": "normal 400 16px/24px --fontFamily-googleRoboto",
            "margin": "0px 0 0px 0",
            "lg-border-width": "0px 0px 0px 2px",
            "href": "https://docs.cdzv.com/code-zero-visual-trading_en/support/term-of-use",
            "text-decoration-line": "initial",
            "color": "--darkL1",
            "hover-color": "#6d32ec",
            "target": "_blank",
            "children": "Terms of use"
        }
    },
    "link1": {
        "kind": "Link",
        "props": {
            "margin": "0px 0 0px 0",
            "display": "flex",
            "href": "https://docs.cdzv.com/code-zero-visual-trading_en/support/privacy-policy",
            "font": "normal 400 16px/24px --fontFamily-googleRoboto",
            "text-decoration-line": "initial",
            "color": "--darkL1",
            "hover-color": "#6d32ec",
            "target": "_blank",
            "children": "Privacy Policy"
        }
    },
    "link2": {
        "kind": "Link",
        "props": {
            "margin": "0px 0 0px 0",
            "hover-color": "#6d32ec",
            "href": "https://docs.cdzv.com/code-zero-visual-trading_en/support/disclaimer",
            "text-decoration-line": "initial",
            "color": "--darkL1",
            "font": "normal 400 16px/24px --fontFamily-googleRoboto",
            "display": "flex",
            "target": "_blank",
            "children": "Disclaimer"
        }
    },
    "link3": {
        "kind": "Link",
        "props": {
            "margin": "0px 0 0px 0",
            "hover-color": "#6d32ec",
            "font": "normal 400 16px/24px --fontFamily-googleRoboto",
            "text-decoration-line": "initial",
            "color": "--darkL1",
            "display": "flex",
            "href": "https://affiliate.cdzv.com/sign-up",
            "target": "_blank",
            "children": "Affiliate program"
        }
    },
    "box2": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "sm-width": "100%",
            "sm-padding": "0px 0px 0px 0",
            "display": "flex",
            "flex-direction": "column",
            "align-items": "flex-start"
        }
    },
    "text1": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 20px 0px",
            "font": "normal 500 25px/1.5 --fontFamily-sans",
            "children": "Contact"
        }
    },
    "text2": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 20px 0px",
            "font": "--base",
            "color": "#5a5d64",
            "children": "passeig Garcia Faria, 75, Barcelona, 08019"
        }
    },
    "link4": {
        "kind": "Text",
        "props": {
            "href": "#",
            "color": "#5a5d64",
            "text-decoration-line": "initial",
            "font": "--base",
            "display": "inline-block",
            "margin": "0px 0px 15px 0px",
            "hover-color": "#6d32ec",
            "children": <>
                {/*<strong>PT TREN ALGO GLOBAL</strong><br/><br/>*/}
                <a href={"mailto:support@cdzv.com"} target={"_blank"} rel={"noopener noreferrer"}>EMail</a><br/>
                <a href={"https://discord.com/channels/1203072937335455784/1223217249918259200"}
                   target={"_blank"}
                   rel={"noopener noreferrer"}
                >Discord</a><br/>
                <a href={"https://t.me/cdzv_com_support_bot"} target={"_blank"} rel={"noopener noreferrer"}>Telegram</a>
            </>
        }
    },
    "box3": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "sm-width": "100%",
            "sm-padding": "0px 0px 0px 0",
            "md-width": "100%"
        }
    },
    "text3": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 20px 0px",
            "font": "normal 500 25px/1.5 --fontFamily-sans",
            "children": "Solution"
        }
    },
    "text4": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 40px 0px",
            "font": "--base",
            "color": "#5a5d64",
            "children": "The smartest way to test, optimize and automate your trading strategies."
        }
    },
    "box4": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px",
            "display": "flex",
            "grid-template-columns": "repeat(5, 1fr)",
            "grid-gap": "16px 24px",
            "md-align-self": "flex-start",
            "sm-align-self": "center",
            "justify-items": "start",
            "align-items": "center",
            "justify-content": "flex-start"
        }
    },
    "linkBox": {
        "kind": "LinkBox",
        "props": {
            "href": "https://discord.com/invite/cu5tPqz4ft",
            "target": "_blank",
            "pointer-events": "auto"
        }
    },
    "icon": {
        "kind": "Icon",
        "props": {
            "category": "fa",
            "icon": FaDiscord,
            "size": "24px",
            "color": "#5a5d64",
            "hover-color": "#6d32ec",
            "transition": "background-color 1s ease 0s"
        }
    },
    "linkBox1": {
        "kind": "LinkBox",
        "props": {
            "href": "https://www.reddit.com/r/CodeZeroVisualTrading/",
            "target": "_blank"
        }
    },
    "icon1": {
        "kind": "Icon",
        "props": {
            "category": "fa",
            "icon": FaRedditSquare,
            "size": "24px",
            "color": "#5a5d64",
            "hover-color": "#6d32ec",
            "transition": "background-color 1s ease 0s"
        }
    },
    "linkBox2": {
        "kind": "LinkBox",
        "props": {
            "href": "mailto:support@cdzv.com?I need help",
            "target": "_blank"
        }
    },
    "icon2": {
        "kind": "Icon",
        "props": {
            "category": "fa",
            "icon": FaMailBulk,
            "size": "24px",
            "color": "#5a5d64",
            "hover-color": "#6d32ec",
            "transition": "background-color 1s ease 0s"
        }
    },
    "linkBox3": {
        "kind": "LinkBox",
        "props": {
            "href": "https://www.youtube.com/@cdzv_info",
            "target": "_blank"
        }
    },
    "icon3": {
        "kind": "Icon",
        "props": {
            "category": "fa",
            "icon": FaYoutubeSquare,
            "size": "24px",
            "color": "#5a5d64",
            "hover-color": "#6d32ec",
            "transition": "background-color 1s ease 0s"
        }
    },
    "box5": {
        "kind": "Box",
        "props": {
            "display": "block",
            "padding": "0 0px 0px 0px",
            "border-color": "--color-lightD2",
            "md-padding": "0 0px 0px 0px",
            "md-flex-direction": "column"
        }
    },
    "text5": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 0px 0px",
            "font": "--base",
            "color": "#5a5d64",
            "md-margin": "0px 0px 25px 0px",
            "sm-text-align": "center",
            "text-align": "center",
            "letter-spacing": "1px",
            "children": <>
                <hr style={{
                    color: "#cececece"
                }}/>
                <span style={{
                    fontSize: "13px"
                }}>
                CDZV.COM provides software only. Any references to trading, exchange, transfer, or wallet services, etc. are references to services provided by third-party service providers.
                </span>
                <br/><br/>
                © 2023-2025 | CDZV.COM | All rights reserved.
            </>
        }
    }
};

const CdzvFooter = props => {
    const {
        override,
        children,
        rest
    } = useOverrides(props, overrides, defaultProps);
    return <Section {...rest}>
        <Box {...override("box")}>
            <Box {...override("box1")}>
                <Text {...override("text")} />
                <Link {...override("link")} />
                <Link {...override("link1")} />
                <Link {...override("link2")} />
                <Link {...override("link3")} />
            </Box>
            <Box {...override("box2")}>
                <Text {...override("text1")} />
                <Text {...override("text2")} />
                <Text {...override("link4")} />
            </Box>
            <Box {...override("box3")}>
                <Text {...override("text3")} />
                <Text {...override("text4")} />
                <Box {...override("box4")}>
                    <LinkBox {...override("linkBox")}>
                        <Icon {...override("icon")} />
                    </LinkBox>
                    <LinkBox {...override("linkBox1")}>
                        <Icon {...override("icon1")} />
                    </LinkBox>
                    <LinkBox {...override("linkBox2")}>
                        <Icon {...override("icon2")} />
                    </LinkBox>
                    <LinkBox {...override("linkBox3")}>
                        <Icon {...override("icon3")} />
                    </LinkBox>
                </Box>
            </Box>
        </Box>
        <Box {...override("box5")}>
            <Text {...override("text5")} />
        </Box>
        {children}
    </Section>;
};

Object.assign(CdzvFooter, {
    ...Section,
    defaultProps,
    overrides
});
export default CdzvFooter;